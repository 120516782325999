.highlight,
.highlight * {
  background: #e6edec !important;
}

/* .lm_tab {
  height: 40px !important;
} */

.lm_tab {
  height: 31px !important;
  line-height: 31px;
}

.lm_close_tab {
  height: 26px !important;
}

.lm_controls {
  top: 4px;
}

.lm_title {
  font-weight: bold;
  font-size: larger;
}

a.nav-link {
  font-size: initial;
  font-weight: 800;
  color: white;
}

.container-element {
  padding-top: 46.5px;
}

.dropDownButton {
  color: black !important;
  font-weight: 400 !important;
  text-align: center !important;
  padding: 0 !important;
}

.MuiTablePagination-displayedRows {
  margin: 0;
}
.MuiTablePagination-selectLabel {
  margin: 0;
}

.css-nfmglb-MuiTableCell-root {
  padding-right: 1px !important;
  padding-left: 1px !important;
}

.MuiIconButton-root {
  padding: 0px !important;
  height: 20px;
  margin: 0px !important;
}

/* .css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar {
  min-height: 39px !important;
} */

.MuiTableCell-footer {
  height: 40px;
}

.MuiToolbar-gutters {
  min-height: 39px !important;
}

.highlightClass {
  background-color: transparent;
  font-weight: bold;
}

.highlightSemantic {
  background-color: #17a2b8;
}

.highlightSemanticClicked {
  background-color: #b0e0e6;
}

.popup-container {
  margin-top: 100px;
  margin-bottom: 120px;
}

.textFieldComp > div > input {
  padding: 0px;
  padding-left: 4px;
}

.container-item {
  text-align: left;
  display: inline-block;
}
.chips-container {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.center {
  text-align: center;
  display: inline-block;
  width: 100%;
}

.dropDownOptions > div > div {
  padding-top: 4px;
  padding-bottom: 4px;
}

.associated {
  color: red;
}

span.associated:hover {
  color: rgb(145, 44, 44);
}

.droppable-box {
  /* border-style: dotted; */
  width: 300px;
  height: 20px;
}

.droppable-box-mini {
  /* border-style: dotted; */
  width: 300px;
  height: 10px;
  /* position: absolute; */
}

.dragging {
  border-bottom: 2px solid rgb(92, 91, 91);
}

div.droppable-box-mini-hover:hover {
  color: #61dafb;
  background-color: aquamarine;
}

.typeaheadSelected > div > input {
  font-weight: bolder;
}

/*
.chooseColor {
  width: 10px;
  height: 10px;
  background: #fff;
  float: left;
  margin: 5px 5px 0 0;
  cursor: pointer;
}

.chooseColor .selectedColor {
  width: 10px;
  height: 10px;
}

.chooseColor ul {
  display: none;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
}

.chooseColor:hover ul {
  display: block;
}

.chooseColor ul,
.chooseColor ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.chooseColor ul li {
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.chooseColor ul li:hover {
  border: 1px solid orange;
  width: 8px;
  height: 8px;
} */
